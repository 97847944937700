import {Component, OnInit} from '@angular/core';
import {MatDialogContent, MatDialogRef, MatDialogTitle} from '@angular/material/dialog';
import {ParamWebservice} from '../../../../services/webservices/param.webservice';
import {Destroyed} from '../../directives/destroyed.directive';
import {PDocument} from '../../../../model/param/p-document.model';
import {AlertService} from '../../../../services/alert.service';
import {
  FormsModule,
  ReactiveFormsModule,
  UntypedFormArray,
  UntypedFormControl,
  UntypedFormGroup,
  Validators
} from '@angular/forms';
import {formatDate, NgFor, NgIf} from '@angular/common';
import {MatInputModule} from '@angular/material/input';
import {MatFormFieldModule} from '@angular/material/form-field';
import {MatIconModule} from '@angular/material/icon';
import {MatButtonModule} from '@angular/material/button';
import {DragDropDirective} from '../../directives/drag-drop.directive';
import {MatOptionModule} from '@angular/material/core';
import {MatSelectModule} from '@angular/material/select';
import {AlertComponent} from '../../alert/alert/alert.component';

@Component({
  selector: 'app-dialog-add-doc-annexe',
  templateUrl: './dialog-add-doc-annexe.component.html',
  styleUrls: ['./dialog-add-doc-annexe.component.scss'],
  standalone: true,
  imports: [
    MatDialogTitle,
    AlertComponent,
    MatDialogContent,
    MatSelectModule,
    FormsModule,
    NgFor,
    MatOptionModule,
    DragDropDirective,
    MatButtonModule,
    MatIconModule,
    NgIf,
    MatFormFieldModule,
    MatInputModule,
    ReactiveFormsModule
  ]
})
export class DialogAddDocAnnexeComponent extends Destroyed implements OnInit {
  pDocuments: PDocument[];
  filesAdded = [];

  validExtensions: string[] = ['jpg', 'jpeg', 'png', 'pdf', 'doc', 'docx'];

  controls: UntypedFormArray;

  selectedDocType;

  constructor(
    public dialogRef: MatDialogRef<DialogAddDocAnnexeComponent>,
    private readonly paramWebservice: ParamWebservice,
    private readonly alertService: AlertService
  ) {
    super();
  }

  ngOnInit() {
    this.initPDocument();
  }

  close() {
    this.dialogRef.close();
  }

  initPDocument() {
    this.paramWebservice
      .getAllDocuments()
      .pipe(this.untilDestroyed())
      .subscribe((value) => {
        this.pDocuments = value;
      });
  }

  onFileAdded($event: File[]) {
    if (this.selectedDocType) {
      const tempListOfFilesAdded = [];
      this.filesAdded.forEach((f) => tempListOfFilesAdded.push(f));
      for (const file of $event) {
        tempListOfFilesAdded.push({file, idPDocument: this.selectedDocType});
      }
      if (this.checkExtension(tempListOfFilesAdded)) {
        this.alertService.error(
          "Format incorrect: Un ou plusieurs fichiers n'ont pas le bon format. Seuls les types de fichiers suivants sont acceptés :\n● pdf\n● jpg\n● jpeg\n● png\n● pdf\n● word",
          'documentAlert'
        );
      } else {
        this.filesAdded = tempListOfFilesAdded;
        this.createForm();
      }
    } else {
      this.alertService.error("Selectionner le type de document d'abord !", 'documentAlert');
    }
  }

  createForm() {
    const toGroups = this.filesAdded.map((file, index) => {
      // On retire l'extension du fichier
      //const fileNameWithoutExtArray = file.name.split('.');
      //fileNameWithoutExtArray.pop();
      const date = new Date();
      const docLabelSelected = this.pDocuments
        .find((item) => item.id === file.idPDocument)
        .label.replace(/[^\w\s]/gi, '_')
        .split(' ')
        .join('_')
        .toLowerCase();
      const newFileName = `${formatDate(date, 'yyyyMMddHHmmss', 'fr-FR')}_${docLabelSelected}`;

      // On recrée le nom à partir de l'array (dans le cas où le nom contient des .)
      //const fileNameWithoutExt = fileNameWithoutExtArray.join('.');
      return new UntypedFormGroup({
        name: new UntypedFormControl(newFileName, Validators.required),
        idPDocument: new UntypedFormControl(file.idPDocument, Validators.required)
      });
    });
    this.controls = new UntypedFormArray(toGroups);
  }

  checkExtension(listOfFiles) {
    let isExtensionInvalid = false;
    listOfFiles.forEach((f) => {
      const ext: string = f.file.name.split('.').pop();
      if (!this.validExtensions.find((e) => e === ext.toLowerCase())) {
        isExtensionInvalid = true;
      }
    });
    return isExtensionInvalid;
  }

  getControl(index: number, field: string) {
    return this.controls.at(index).get(field);
  }

  getDocType(index: number) {
    return this.pDocuments.find(
      (item) => item.id === this.controls.at(index).get('idPDocument').value
    ).label;
  }

  onNameEdited(index) {
    // On récupère l'extension du fichier
    const fileExt = this.filesAdded[index].file.name.split('.').pop();

    // On crée un nouveau File car la classe File n'a pas de setter .-.
    const myFile = this.filesAdded[index].file;
    const myNewFile = new File([myFile], this.controls.controls[index].value.name + '.' + fileExt, {
      type: myFile.type
    });
    this.filesAdded[index].file = myNewFile;
  }

  deleteFile(index: number) {
    this.filesAdded.splice(index, 1);
    this.createForm();
  }

  saveFiles() {
    if (this.filesAdded && this.filesAdded.length > 0) {
      for (let i = 0; i < this.filesAdded.length; i++) {
        this.onNameEdited(i);
      }
      this.dialogRef.close(this.filesAdded);
    } else {
      this.alertService.warn("Aucun document n'est importé", 'documentAlert');
    }
  }
}
