import {Component, OnInit} from '@angular/core';
import {Destroyed} from '../../directives/destroyed.directive';
import {FormsModule, UntypedFormBuilder} from '@angular/forms';
import {FormatService} from '../../../../services/format.service';
import {ParamWebservice} from '../../../../services/webservices/param.webservice';
import {EntrepriseWebservice} from '../../../../services/webservices/entreprise.webservice';
import {AlertService} from '../../../../services/alert.service';
import {YoungWebservice} from '../../../../services/webservices/young.webservice';
import {DialogAddMonitoringComponent} from '../dialog-add-monitoring/dialog-add-monitoring.component';
import {
  MatDialogActions,
  MatDialogContent,
  MatDialogRef,
  MatDialogTitle
} from '@angular/material/dialog';
import {AnswerTypeEnum} from '../../../../model/enums/answer-type.enum';
import {CompareService} from '../../../../services/compare.service';
import {FseWebservice} from '../../../../services/webservices/fse.webservice';
import {Survey} from '../../../../model/survey/survey.model';
import {YoungAnswer} from '../../../../model/survey/young-answer.model';
import {Question} from '../../../../model/survey/question.model';
import {MatButtonModule} from '@angular/material/button';
import {AsyncPipe, NgFor, NgIf} from '@angular/common';
import {debounceTime} from "rxjs/operators";

@Component({
  selector: 'app-dialog-questionnaire',
  templateUrl: './dialog-questionnaire.component.html',
  styleUrls: ['./dialog-questionnaire.component.scss'],
  standalone: true,
  imports: [
    MatDialogTitle,
    MatDialogContent,
    NgIf,
    NgFor,
    FormsModule,
    MatDialogActions,
    MatButtonModule,
    AsyncPipe
  ]
})
export class DialogQuestionnaireComponent extends Destroyed implements OnInit {
  idYoung: number;
  survey: Survey;
  youngAnswers: YoungAnswer[] = [];

  isQuestionnaireLoaded: Promise<boolean>;

  answerTypeEnum = AnswerTypeEnum;

  listOfQuestions: Question[] = [];

  isAlreadySend: boolean = false;

  constructor(
    private readonly dialogRef: MatDialogRef<DialogAddMonitoringComponent>,
    private readonly fb: UntypedFormBuilder,
    private readonly formatService: FormatService,
    private readonly paramWebservice: ParamWebservice,
    private readonly entrepriseWebservice: EntrepriseWebservice,
    private readonly alertService: AlertService,
    private readonly youngWebservice: YoungWebservice,
    private readonly compareService: CompareService,
    private readonly fseWebservice: FseWebservice
  ) {
    super();
  }

  ngOnInit() {
    if (this.youngAnswers && this.youngAnswers.length > 0) {
      if (this.youngAnswers[0].idSurvey !== this.survey.id) {
        this.loadQuestionnaire(new Date(this.survey.dateStart).getFullYear());
      } else {
        this.listOfQuestions = [];
        this.youngAnswers.forEach((value) => {
          this.listOfQuestions.push(value.question);
        });
        this.listOfQuestions.sort((a, b) => this.compareService.compareNumber(a.order, b.order));
        this.isQuestionnaireLoaded = Promise.resolve(true);
      }
    } else {
      this.loadQuestionnaire(new Date(this.survey.dateStart).getFullYear());
    }
  }

  updateAnswerYoung(id: number, event: Event, answerTypeEnum: AnswerTypeEnum) {
    const target = event.target as HTMLInputElement;

    if (this.youngAnswers.find((item) => item.idQuestion === id)) {
      if (answerTypeEnum === AnswerTypeEnum.SELECT) {
        this.youngAnswers.find((item) => item.idQuestion === id).idAnswer = parseInt(
          target.value,
          10
        );
      } else if (
        answerTypeEnum === AnswerTypeEnum.TRUE_FALSE ||
        answerTypeEnum === AnswerTypeEnum.FREE
      ) {
        this.youngAnswers.find((item) => item.idQuestion === id).answer = target.value;
      } else {
        this.youngAnswers.find((item) => item.idQuestion === id).answer = event.toString();
      }
    } else {
      const youngAnswer = new YoungAnswer();
      if (
        answerTypeEnum === AnswerTypeEnum.SELECT ||
        answerTypeEnum === AnswerTypeEnum.TRUE_FALSE ||
        answerTypeEnum === AnswerTypeEnum.FREE
      ) {
        youngAnswer.idAnswer = parseInt(target.value, 10);
        youngAnswer.idQuestion = id;
        youngAnswer.idYoung = this.idYoung;
      } else {
        youngAnswer.answer = event.toString();
        youngAnswer.idQuestion = id;
        youngAnswer.idYoung = this.idYoung;
      }
      this.youngAnswers.push(youngAnswer);
    }
  }

  isAnswered(idQuestion: number) {
    return this.youngAnswers.find((item) => item.idQuestion === idQuestion);
  }

  isAnswerSelected(idQuestion: number, idAnswer: number) {
    return this.youngAnswers.find(
      (item) => item.idQuestion === idQuestion && item.idAnswer === idAnswer
    );
  }

  isAnswerSelectedTrueFalse(idQuestion: number, answer: string) {
    return this.youngAnswers.find(
      (item) => item.idQuestion === idQuestion && item.answer === answer
    );
  }

  getAnswer(idQuestion: number) {
    if (this.youngAnswers && this.youngAnswers.some((item) => item.idQuestion === idQuestion)) {
      return this.youngAnswers.find((item) => item.idQuestion === idQuestion).answer;
    } else {
      return 'false';
    }
  }

  resetValue(event: Event) {
    const target = event.target as HTMLInputElement;

    if (parseInt(target.value, 10) < 0) {
      target.value = '0';
    }
  }

  loadQuestionnaire(year) {
    this.fseWebservice
      .getQuestionnaire(year)
      .pipe(this.untilDestroyed())
      .subscribe((value) => {
        this.listOfQuestions = value.listOfQuestion;
        if (this.youngAnswers && this.youngAnswers.length > 0) {
          this.setAnswerForNewSurvey();
        } else {
          this.initYoungAnswers(this.listOfQuestions);
        }
        this.listOfQuestions.sort((a, b) => this.compareService.compareNumber(a.order, b.order));
        this.isQuestionnaireLoaded = Promise.resolve(true);
      });
  }

  initYoungAnswers(listOfQuestion: Question[]) {
    listOfQuestion.forEach((item) => {
      if (!this.youngAnswers.some((youngAnswer) => youngAnswer.idQuestion === item.id)) {
        const youngAnswer = new YoungAnswer();
        if (item.answerTypeEnum === AnswerTypeEnum.SELECT) {
          youngAnswer.idQuestion = item.id;
        } else if (item.answerTypeEnum === AnswerTypeEnum.TRUE_FALSE) {
          youngAnswer.answer = null;
          youngAnswer.idQuestion = item.id;
        } else if (item.answerTypeEnum === AnswerTypeEnum.NUMBER) {
          youngAnswer.answer = '0';
          youngAnswer.idQuestion = item.id;
        } else {
          youngAnswer.answer = '';
          youngAnswer.idQuestion = item.id;
        }
        youngAnswer.idSurvey = this.survey.id;
        youngAnswer.idYoung = this.idYoung;
        this.youngAnswers.push(youngAnswer);
      }
    });
  }

  setAnswerForNewSurvey() {
    const newAnswers = [];
    this.listOfQuestions.forEach((question) => {
      if (this.youngAnswers.some((item) => item.question.label === question.label)) {
        const newAnswer = Object.assign(
          {},
          this.youngAnswers.find((item) => item.question.label === question.label)
        );
        newAnswer.id = null;
        if (newAnswer.idSurvey !== this.survey.id) {
          newAnswer.idSurvey = this.survey.id;
        }
        newAnswer.idQuestion = question.id;
        newAnswer.dateAnswer = null;
        newAnswer.answerModel = null;
        newAnswer.question = null;
        newAnswer.survey = null;
        if (
          this.youngAnswers.find((quest) => quest.question.label === question.label).answerModel
        ) {
          const labelAnswer = this.youngAnswers.find(
            (quest) => quest.question.label === question.label
          ).answerModel.label;
          const listOfNewAnswer = question.listOfAnswer;
          newAnswer.idAnswer = listOfNewAnswer?.find((item) => item.label === labelAnswer)?.id;
        }
        newAnswers.push(newAnswer);
      }
    });

    this.youngAnswers = newAnswers;

    this.initYoungAnswers(
      this.listOfQuestions.filter(
        (item) => !this.youngAnswers.find((youngAnswer) => youngAnswer.idQuestion === item.id)
      )
    );
  }

  save() {
    if (!this.isAlreadySend) {
      this.isAlreadySend = true;
      this.youngWebservice
        .updateSurvey(this.youngAnswers)
        .pipe(this.untilDestroyed(), debounceTime(200))
        .subscribe(() => {
          this.isAlreadySend = false;
          this.dialogRef.close();
        });
    }
  }

  hasErrorFseQuestionnaire() {
    let hasError = false;
    if (this.youngAnswers && this.youngAnswers.length > 0) {
      this.youngAnswers.forEach((item) => {
        if (!hasError) {
          if (
            (item.idAnswer === null || item.idAnswer === undefined) &&
            (item.answer === null || item.answer === undefined || item.answer === '')
          ) {
            hasError = true;
          }
        }
      });
    } else {
      hasError = true;
    }
    return hasError;
  }
}
