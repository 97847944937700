<p mat-dialog-title class="text-primary text-center">Questionnaire FSE</p>
<mat-dialog-content>
  <div class="questionnaire-fse">
    <ng-container *ngIf="isQuestionnaireLoaded | async">
      <div *ngFor="let question of listOfQuestions" class="row">
        <ng-container *ngIf="question.answerTypeEnum === answerTypeEnum.SELECT">
          <label class="m-0 py-2">{{question.label}}</label>
          <select (change)="updateAnswerYoung(question.id, $event, question.answerTypeEnum)"
                  *ngIf="!isAnswered(question.id)" [id]="question.id"
                  class="form-control">
            <option *ngIf="!question.listOfAnswer || question.listOfAnswer.length === 0"
                    [value]="null">Aucun élément
            </option>
            <option *ngIf="question.listOfAnswer && question.listOfAnswer.length !== 0"
                    [value]="null"></option>
            <option *ngFor="let item of question.listOfAnswer" [value]="item.id">{{item.label}}</option>
          </select>
          <select (change)="updateAnswerYoung(question.id, $event, question.answerTypeEnum)"
                  *ngIf="isAnswered(question.id)" [id]="question.id"
                  class="form-control">
            <option *ngIf="!question.listOfAnswer || question.listOfAnswer.length === 0"
                    [value]="null">Aucun élément
            </option>
            <option *ngIf="question.listOfAnswer && question.listOfAnswer.length !== 0"
                    [value]="null"></option>
            <option *ngFor="let item of question.listOfAnswer"
                    [selected]="isAnswerSelected(question.id, item.id)"
                    [value]="item.id">{{item.label}}</option>
          </select>
        </ng-container>
        <ng-container *ngIf="question.answerTypeEnum === answerTypeEnum.TRUE_FALSE">
          <label class="py-2">{{question.label}}</label>
          <select (change)="updateAnswerYoung(question.id, $event, question.answerTypeEnum)"
                  *ngIf="!isAnswered(question.id)" [id]="question.id"
                  class="form-control">
            <option [value]="null"></option>
            <option [selected]="isAnswerSelectedTrueFalse(question.id, 'true')" [value]=true>Oui</option>
            <option [selected]="isAnswerSelectedTrueFalse(question.id, 'false')" [value]=false>Non</option>
          </select>
          <select (change)="updateAnswerYoung(question.id, $event, question.answerTypeEnum)"
                  *ngIf="isAnswered(question.id)" [id]="question.id"
                  class="form-control">
            <option [value]="null"></option>
            <option [selected]="isAnswerSelectedTrueFalse(question.id, 'true')" [value]="true">Oui</option>
            <option [selected]="isAnswerSelectedTrueFalse(question.id, 'false')" [value]=false>Non</option>
          </select>
        </ng-container>
        <ng-container *ngIf="question.answerTypeEnum === answerTypeEnum.FREE">
          <label class="m-0 py-1" style="margin-top: 1.2rem;">{{question.label}}</label>
          <input (change)="updateAnswerYoung(question.id, $event, question.answerTypeEnum)"
                 *ngIf="!isAnswered(question.id)"
                 class="form-control" type="text"/>
          <input (change)="updateAnswerYoung(question.id, $event, question.answerTypeEnum)"
                 *ngIf="isAnswered(question.id)"
                 [value]="getAnswer(question.id)"
                 class="form-control" type="text"/>
        </ng-container>
        <ng-container *ngIf="question.answerTypeEnum === answerTypeEnum.NUMBER">
          <label class="m-0 py-1" style="margin-top: 1.2rem;">{{question.label}}</label>
          <input
            (change)="updateAnswerYoung(question.id, $event.target.value, question.answerTypeEnum)"
            (keyup)="resetValue($event)"
            *ngIf="!isAnswered(question.id)"
            class="form-control text-center"
            type="number">
          <input
            (change)="updateAnswerYoung(question.id, $event.target.value, question.answerTypeEnum)"
            (keyup)="resetValue($event)"
            *ngIf="isAnswered(question.id)"
            [value]="getAnswer(question.id)"
            class="form-control text-center"
            type="number">
        </ng-container>
      </div>
    </ng-container>
  </div>
</mat-dialog-content>

<mat-dialog-actions align="center">
  <button [disabled]="isAlreadySend" (click)="save()" mat-button>Sauvegarder les réponses</button>
</mat-dialog-actions>
