import {Component} from '@angular/core';
import {MatDialogRef, MatDialogTitle} from '@angular/material/dialog';
import {UserCheckingDTO} from '../../../../model/dto/user-checking.dto';
import {FormatService} from '../../../../services/format.service';
import {MatButtonModule} from '@angular/material/button';
import {FormsModule} from '@angular/forms';
import {NgFor, NgIf} from '@angular/common';

@Component({
  selector: 'app-dialog-select-young-info-duplicate',
  templateUrl: './dialog-select-young-info-duplicate.component.html',
  styleUrls: ['./dialog-select-young-info-duplicate.component.scss'],
  standalone: true,
  imports: [NgIf, MatDialogTitle, NgFor, FormsModule, MatButtonModule]
})
export class DialogSelectYoungInfoDuplicateComponent {
  result;
  abort = true;
  duplicates: UserCheckingDTO[] = [];

  constructor(
    public dialogRef: MatDialogRef<DialogSelectYoungInfoDuplicateComponent>,
    private readonly formatService: FormatService
  ) {}

  formatName(duplicate: UserCheckingDTO) {
    let name = `${duplicate.firstName} ${duplicate.lastName.toUpperCase()}`;
    if (duplicate.birthday) {
      name += ` - ${this.formatService.formatDate(duplicate.birthday)}`;
    } else {
      name += ` - Aucune date de naissance saisie`;
    }

    if (duplicate.nameCenter) {
      name += ` - ${duplicate.nameCenter}`;
    } else {
      name += ` - Aucun centre saisi`;
    }

    return name;
  }

  exit() {
    this.dialogRef.close(this.result);
  }

  abortAction() {
    this.dialogRef.close();
  }
}
