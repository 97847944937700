<div *ngIf="duplicates && duplicates.length>0" class="dialog-default d-flex flex-column p-4">
  <div class="d-flex flex-row m-2">
    <h1 class="m-0" mat-dialog-title>
      Nous avons trouvé {{duplicates.length > 1 ? 'plusieurs jeunes' : 'un jeune'}} avec les mêmes informations :
    </h1>
    <span class="flex-fill separator"></span>
    <button (click)="abortAction()"
            aria-label="Close"
            class="close"
            type="button">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <p class="my-0">Selectionnez le jeune à fusionner : </p>
  <div class="radio-group my-3 flex-fill">
    <div *ngFor="let duplicate of duplicates" class="custom-control custom-radio">
      <input
        [(ngModel)]="result"
        [value]="duplicate.id"
        class="custom-control-input"
        id="{{'radio' + duplicate.id}}"
        type="radio">
      <label class="custom-control-label" for="{{'radio' + duplicate.id}}">{{formatName(duplicate)}}</label>
    </div>
  </div>
  <div class="my-md-3 my-5 row align-items-center justify-content-end">
    <button (click)="abortAction()" class="mr-3" mat-button>Annuler</button>
    <button (click)="exit()" class="btn btn-primary mr-5">Aller à la page fusion</button>
  </div>
</div>


